import {Ticket} from "@/services/api/models/Ticket";
import dayjs from "dayjs";

export class PersistentStore {

    static #TICKETS_KEY = "tickets"
    static #SKIP_IN_BOARDING_KEY = "skip_in_boarding"
    static #STAFF_TOKEN = "staff_token"
    static #ALLOWED_SALLES = "allowed_salles"
    static #VERSION = "version"
    static #FAVORITE_SHOWS = "favorite_shows"

    static get tickets() {
        const tickets = localStorage.getItem(PersistentStore.#TICKETS_KEY)

        if (!tickets) {
            return []
        }

        try {
            return JSON.parse(tickets)
                .map(t => Ticket.fromJson(t))
                .filter(t => dayjs(t.category.validity_end).isAfter(dayjs().subtract(5, 'day')))
        } catch (e) {
            console.warn(`${PersistentStore.#TICKETS_KEY} storing invalid json`)
            return []
        }
    }

    static set tickets(tickets) {
        if (!Array.isArray(tickets) || tickets.some(c => !(c instanceof Ticket))) {
            console.error("PersistentStore can only store an array of Tickets")
            return
        }

        tickets.forEach(t => {
            t.category.validity_start = dayjs(t.category.validity_start).format()
            t.category.validity_end = dayjs(t.category.validity_end).format()
        })

        localStorage.setItem(PersistentStore.#TICKETS_KEY, JSON.stringify(tickets))
    }

    static updateTickets(updatedTickets) {
        this.tickets = this.tickets.map(t => t.update(updatedTickets.find(ut => ut.id === t.id)));
    }

    static addTicket(ticket) {
        if (!(ticket instanceof Ticket)) {
            console.error("PersistentStore can only add a value of type Ticket")
            return
        }

        this.tickets = [...this.tickets.filter(t => t.id !== ticket.id), ticket]
    }

    static removeTicket(ticket) {
        if (!(ticket instanceof Ticket)) {
            console.error("PersistentStore can only add a value of type Ticket")
            return
        }

        this.tickets = this.tickets.filter(t => t.id !== ticket.id)
    }


    /**
     * The user don't want to add a ticket and only wants to see the planning
     * @returns {boolean}
     */
    static get skipInBoarding() {
        const val = localStorage.getItem(this.#SKIP_IN_BOARDING_KEY) ?? '0';
        return val === '1';
    }

    static set skipInBoarding(skip) {
        localStorage.setItem(this.#SKIP_IN_BOARDING_KEY, skip ? '1' : '0');
    }

    static MD5_REGEX = /^[a-f0-9]{32}$/i;

    static set staffToken(token) {
        if (this.MD5_REGEX.test(token)) {
            localStorage.setItem(this.#STAFF_TOKEN, token);
        } else {
            localStorage.removeItem(this.#STAFF_TOKEN);
        }
    }

    static get staffToken() {
        const token = localStorage.getItem(this.#STAFF_TOKEN);

        if (!this.MD5_REGEX.test(token)) {
            localStorage.removeItem(this.#STAFF_TOKEN);
            return null;
        }

        return token;
    }

    static get isStaffLoggedIn() {
        return this.staffToken !== null;
    }

    static logOffStaff() {
        localStorage.removeItem(this.#STAFF_TOKEN);
    }

    static clearMatching(part) {
        const keys = [];
        for (let i = 0; i < localStorage.length; i++) {
            keys.push(localStorage.key(i));
        }

        keys
            .filter(k => k.includes(part))
            .forEach(k => localStorage.removeItem(k))
    }


    static set allowedSalles(salles){
        salles = salles ?? [];
        localStorage.setItem(this.#ALLOWED_SALLES, JSON.stringify(salles))
    }

    static get allowedSalles(){
        if(!this.isStaffLoggedIn){
            return [];
        }

        const ids = localStorage.getItem(this.#ALLOWED_SALLES)
        return JSON.parse(ids) ?? [];
    }

    static set version(version){
        localStorage.setItem(this.#VERSION, version)
    }

    static get version(){
        return localStorage.getItem(this.#VERSION)
    }

    // TODO remove
    static api = undefined;

    /**
     * @param {number} showId
     * @returns {boolean}
     */
    static isFavoriteShow(showId){
        return this.favoriteShows.has(showId);
    }

    /**
     * @param {number} showId
     */
    static addFavoriteShow(showId){
        const favouriteShows = this.favoriteShows;
        favouriteShows.add(showId)
        this.favoriteShows = favouriteShows
    }

    /**
     * @param {number} showId
     */
    static removeFavoriteShow(showId){
        const favouriteShows = this.favoriteShows;
        favouriteShows.delete(showId)
        this.favoriteShows = favouriteShows
    }

    /**
     * @param {Set<number>} favouriteShows
     */
    static set favoriteShows(favouriteShows){
        localStorage.setItem(this.#FAVORITE_SHOWS, JSON.stringify([...favouriteShows] ?? []))
    }

    /**
     * @returns {Set<number>}
     */
    static get favoriteShows(){
        const json = JSON.parse(localStorage.getItem(this.#FAVORITE_SHOWS)) ?? [];
        return new Set(json)
    }
}