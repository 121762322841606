import axios from "axios";
import {global} from "@/stores/global";
import {PersistentStore} from "@/services/PersistentStore";

export class HttpClient {

    #baseURL
    #headers
    #app

    constructor(options = {}) {
        this.#baseURL = options.baseURL || "v1"
        this.#headers = options.headers || {}
        this.#app = options.app
    }

    get online() {
        return window.navigator.onLine
    }

    async get(url) {
        return new Promise(resolve => {
            global.addLoading()
            axios
                .get(this.urlWithBase(url), HttpClient.config())
                .then(r => resolve({
                    data: r.data,
                    fromCache: false,
                    online: this.online,
                    timestamp: this.timeStampSec()
                }))
                .catch((e) => this.handleError(e))
                .finally(() => global.removeLoading())
        })
    }

    async getCached(url, seconds = 30) {
        const time = this.timeStampSec()
        let item
        let resultItem = {
            data: null,
            fromCache: false,
            online: this.online,
            timestamp: null
        }

        // Try to get the cached data
        try {
            item = JSON.parse(localStorage.getItem(url))
            resultItem.data = item.data ?? null
            resultItem.fromCache = true
            resultItem.timestamp = item.timestamp ?? time - 1000000
        } catch (e) {
            resultItem = null;
        }

        // Not in cache or expired. If offline and expired ignore the expiration time.
        if (!resultItem || !resultItem.data || (resultItem.timestamp + seconds < time && this.online)) {
            resultItem = await this.get(url)

            if (resultItem.data) {
                localStorage.setItem(url, JSON.stringify({data: resultItem.data, timestamp: time}))
            } else {
                localStorage.removeItem(url)
            }
        }

        return resultItem
    }

    async post(url, data) {
        const result = await axios
            .post(this.urlWithBase(url), data, HttpClient.config())

        return {
            data: result.data,
            status: result.status,
            fromCache: false,
            online: this.online,
            timestamp: this.timeStampSec()
        }
    }

    async delete(url, data) {
        const result = await axios
            .delete(this.urlWithBase(url), {data})

        return {
            data: result.data,
            status: result.status,
            fromCache: false,
            online: this.online,
            timestamp: this.timeStampSec()
        }
    }

    static config() {
        let config = {
            headers: {},
            validateStatus: status => status < 500
        }

        if (PersistentStore.isStaffLoggedIn)
            config.headers.Authorization = `Bearer ${PersistentStore.staffToken}`

        return config;
    }

    static configCors() {
        return {
            headers: {},
            validateStatus: status => status < 500
        };
    }

    timeStampSec() {
        return Date.now() / 1000;
    }

    handleError(error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error("Error while fetching a ressource", error.response);

            this.#app.$toast.error("Oups! Une erreur s'est produite au niveau du serveur. Réessaie plus tard")

        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.error("The request was made but no response was received");
            this.#app.$toast.error("Le serveur n'a pas répondu à la requête")
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error', error.message);
        }
    }

    // eslint-disable-next-line no-dupe-class-members
    urlWithBase(url) {
        return new URL(url, this.#baseURL).href;
    }
}