import {HttpClient} from "@/services/api/HttpClient";
import {Show} from "@/services/api/models/Show";
import axios from "axios";

export default class Shows extends HttpClient {
    config

    constructor(options, config) {
        super(options);
        this.config = config;
    }

    async all() {
        const response = await this.getCached(`/v1/activities`, 30)
        return Shows.#getData(response)
    }

    async allStaff() {
        const response = await this.getCached(`/v1/activities`, 10)
        return Shows.#getData(response)
    }

    async byId(id) {
        const response = await this.get(`/v1/activities/${id}`)
        return Show.fromJson(response.data);
    }

    async next(id) {
        return await this.post(`/v1/activities/${id}/next`)
    }

    async last_called(id) {
        return await this.get(`/v1/activities/${id}/last_called`)
    }

    async startScanning(showId) {
        return await axios
            .post(`https://eventws.ch/console/ajax/scanningstatus.php?show_id=${showId}&scanning=1`, HttpClient.config())
    }

    async endScanning(showId) {
        return await axios
            .post(`https://eventws.ch/console/ajax/scanningstatus.php?show_id=${showId}&scanning=0`, HttpClient.config())
    }

    async close(showId) {
        return await axios
            .post(`https://eventws.ch/console/ajax/scanningstatus.php?show_id=${showId}&close=1`, HttpClient.config())
    }

    static specificFillingLevel(show) {
        if (show.isFull || show.level >= 5) {
            return "full"
        }

        if (show.isCancel) {
            return "cancel"
        }

        if (show.isFlush) {
            return "flush"
        }

        if(show.level < 5) {
            return show.bookingType == 0 ? 'notBookable': 'bookable';
        }

        return null;
    }

    provideShowFillingStatus(show) {
        let level = `${show.level}`;

        return this.config.FillingStatuses[Shows.specificFillingLevel(show) ?? level];
    }

    provideShowFillingStatusStaff(show) {
        if (Shows.specificFillingLevel(show)) {
            return this.provideShowFillingStatus(show) + ` ${show.bookings} / ${show.capacity} places`;
        }
        return `${show.bookings} / ${show.capacity}`
    }

    canBook(show) {
        return !show.isFull
            && !show.isFlush
            && !show.isCancel
            && show.level < 5
            && show.bookingType > 0;
    }

    static #getData(result) {
        const data = result?.data;
        if (!data) {
            throw new Error("No data returned");
        }

        let shows = [];
        for (let i = 0; i < data.length; i++) {
            let show = Show.fromJson(data[i]);
            shows.push(show);
        }

        return shows;
    }
}