<template>
  <pull-refresh
      class="pull-refresh"
      v-model="loading"
      @refresh="refresh"
      loading-text="Mise à jour..."
      pulling-text="Tirer pour rafraîchir"
      loosing-text="Relacher pour rafraîchir"
      success-duration="0"
  >
    <div class="list">
      <div class="chip-filter" v-if="!dedi">
        <label v-for="(value, key) in visibleGroups(salleGroups)" :key="key">
          <input type="radio" :value="key" @input="filterChange" name="chip-filter" :checked="key == filter">
          <span>{{ key }}</span>
        </label>
      </div>
      <planning-item v-for="show in showList" :key="show" :item="show" :salles="salleList" :staff="staff"></planning-item>
      <AlertInfo v-if="showList.length === 0">
        Aucun événement prévu pour cette catégorie
      </AlertInfo>
    </div>
  </pull-refresh>
</template>

<script>
import PlanningItem from "@/components/Common/Planning/PlanningItem";
import AlertInfo from "@/components/Common/System/Alerts/Info";
import {global} from "@/stores/global";
import PullRefresh from "pull-refresh-vue3";
import dayjs from "dayjs";
import {PersistentStore} from "@/services/PersistentStore";

export default {
  name: "Planning",
  props: ["staff", "dedi"],
  components: {
    AlertInfo,
    PlanningItem,
    PullRefresh
  },
  data() {
    return {
      salleGroups: {},
      salleList: [],
      showList: [],
      filter: "Tout",
      defaultGroup: "Tout",
      dediGroup: "Dédicaces",
      showGroup: "Show",
      favoriteGroup: "Favoris",
      loading: false,
      global
    }
  },
  async mounted() {
    const data = await Promise.all([
      this.$api.salles.all(),
      this.getShows() // Preload activities in cache
    ]);

    this.salleList = data[0];

    if(this.staff) {
      this.salleGroups = this.salleList
          .filter(s => s.group)
          .reduce((acc, s) => {
            const key = s.group ?? this.defaultGroup;
            (acc[key] = acc[key] ?? []).push(s.id)
            return acc
          }, {
            "Tout": this.salleList.map(s => s.id),
            "Favoris": this.salleList.map(s => s.id),
          })

    } else {
      this.salleGroups = this.salleList
          .filter(s => s.group)
          .reduce((acc, s) => {
            const key = s.group ?? this.defaultGroup;
            (acc[key] = acc[key] ?? []).push(s.id)
            return acc
          }, {
            "Tout": this.salleList.filter(s => s.group !== this.dediGroup).map(s => s.id),
            "Favoris": this.salleList.filter(s => s.group !== this.dediGroup).map(s => s.id),
            "Shows": this.salleList.filter(s => s.group === this.showGroup).map(s => s.id),
            //"Théâtre": this.salleList.filter(s => s.slug === 'THEATRE').map(s => s.id),
          })

    }

    if(this.dedi){
      await this.filterShows(this.dediGroup)
    } else {
      await this.filterShows(this.defaultGroup)
    }
  },
  async beforeUpdate() {
    if(this.dedi && this.filter !== this.dediGroup){
      await this.filterShows(this.dediGroup)
    }

    if(!this.dedi && this.filter === this.dediGroup){
      await this.filterShows(this.defaultGroup)
    }
  },
  methods: {
    async getShows() {
      const shows = this.$api.shows;
      if(this.staff){
        return await shows.allStaff();
      }

      return await shows.all();
    },
    visibleGroups(groups) {
      if(this.staff) {
        return groups;
      }
      return Object.keys(groups)
          .filter(k => !["Show", this.dediGroup].includes(k))
          .reduce((cur, key) => { return Object.assign(cur, { [key]: groups[key] })}, {});
    },
    async filterChange(event) {
      await this.filterShows(event?.target?.value ?? this.defaultGroup)
    },
    async refresh() {
      const salleIds = this.salleGroups[this.filter] ?? []

      this.showList = (await this.getShows() ?? [])
          .filter(s => !s.isFlush)
          .filter(s => salleIds.includes(s.salle))
          .filter(s => this.filter !== this.favoriteGroup || PersistentStore.isFavoriteShow(s.id))
          .map(s => {
            if(s.capacity == "0")
              s.capacity = null;
            return s;
          }).sort((a,b) => {
            return dayjs(a.start.date).unix() - dayjs(b.start.date).unix()
          });

      this.loading = false
    },
    async filterShows(groupKey) {
      this.filter = groupKey ?? this.defaultGroup

      await this.refresh()
    }
  }
}
</script>

<style scoped>
.list {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  row-gap: 20px;
  padding: 20px 20px 60px;
}

select {

  appearance: none;
  outline: 0;
  border: 0;
  box-shadow: none;

  border-radius: 20px;
  width: fit-content;


  flex: 1;
  padding: 10px 1em;
  color: #fff;
  background-color: #7643d6db;
  background-image: none;
  cursor: pointer;

  margin-left: auto;

  text-align: right;
}

/* Remove IE arrow */
select::-ms-expand {
  display: none;
}

.chip-filter {
  display: flex;
  overflow: scroll hidden;
  text-wrap: avoid;
  flex: 1;
  column-gap: 10px;
  padding-bottom: 5px;
}

.chip-filter::-webkit-scrollbar {
  height: 4px;
  padding-top: 4px;
}


/* Track */
.chip-filter::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.chip-filter::-webkit-scrollbar-thumb {
  background: #939393;
  border-radius: 10px;
  border: solid 1px transparent;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  cursor: pointer;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #efefef;
  border-radius: 10px;
  border: solid 4px transparent;
}


.chip-filter label {
  flex: max-content;
  width: max-content;
  flex: 0 1 auto;
}

.chip-filter input {
  display: none;
}

.chip-filter span {
  background-color: #fff;
  display: block;
  padding: 8px 16px;
  border-radius: 30px;
  cursor: pointer;
  white-space: nowrap;
}

:checked + span {
  color: #fff;
  background: linear-gradient(47deg, #9043d6 0%, #7743d6 100%);
}

.pull-refresh{
  height: 100%;
  overflow-y: auto;
}

</style>