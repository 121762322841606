<template>
  <div class="scanner-view" v-if="hasCamera">
    <div class="video-container">
      <video ref="video"></video>
    </div>
    <!--<canvas width="1000" height="1000" ref="canvas" v-if="hasCamera"></canvas>-->
    <qrcode-scan></qrcode-scan>
  </div>
  <alert-warning v-if="!hasCamera">
    Aucune caméra n'a été trouvée ou l'autorisation a été refusée<br>
    Tu peux saisir le numéro du billet ci-dessous.
  </alert-warning>
</template>

<script>
import jsQR from "jsqr";
import AlertWarning from "@/components/Common/System/Alerts/Warning";
import {QrcodeScan} from "mdue"

export default {
  name: "QrCodeScanner",
  components: {AlertWarning, QrcodeScan,},
  emits: ["scan"],
  data() {
    return {
      hasCamera: true,
      code: "",
      running: true,
      scanned: false,
      canvas: document.createElement("canvas"),
      video: document.createElement("video"),
      lastCode: undefined,
      lastCodeTime: undefined,
    }
  },
  created() {

  },
  mounted() {
    this.hasCamera = true
    this.running = true
    console.log(navigator.mediaDevices.getSupportedConstraints())
    this.video = this.$refs.video;

    navigator.mediaDevices.getUserMedia({
      video: {
        facingMode: "environment"
      }
    }).then((stream) => {
      const video = this.video;

      const [track] = stream.getVideoTracks();
      const capabilities = track.getCapabilities();
      const settings = track.getSettings();

      console.log({capabilities, settings})

      //track.applyConstraints({ frameRate: 20, width: capabilities.width.max, height: capabilities.height.max, focusMode: 'manual', focusDistance: 0 });
      video.srcObject = stream;
      video.setAttribute("playsinline", true); // required to tell iOS safari we don't want fullscreen
      video.play();
      this.video.play()
      requestAnimationFrame(this.updateView);
    }).catch(error => {
      console.error(error)
      this.hasCamera = false;
    });
  },
  unmounted() {
    this.running = false
    // Stop reading video
    this.video.srcObject?.getTracks().forEach(track => track.stop())
  },
  methods: {
    updateView() {
      const canvas = this.canvas;
      const ctx = canvas ? canvas.getContext("2d") : null;
      const video = this.video;

      if (ctx && video) {
        const diff = video.videoWidth - video.videoHeight
        const pxToRemove = {
          x: diff > 0 ? diff / 2 : 0,
          y: diff < 0 ? diff / -2 : 0
        };

        const canvasHeight = canvas.height;
        const canvasWidth = canvas.width;

        ctx.drawImage(video, pxToRemove.x, pxToRemove.y, video.videoWidth - pxToRemove.x, video.videoHeight - pxToRemove.y, 0, 0, canvasWidth, canvasHeight);
        const imageData = ctx.getImageData(0, 0, canvasWidth, canvasHeight);

        const code = jsQR(imageData.data, imageData.width, imageData.height);

        if (code) {
          this.code = code.data;
          this.scanned = true;
          // Only emit events if new code or 3 seconds passed
          if (this.lastCode !== this.code || !this.lastCodeTime || this.lastCodeTime + 2000 < Date.now()) {
            this.lastCode = this.code;
            this.lastCodeTime = Date.now();
            this.$emit("scan", this.code);
          }
        }
      }

      if (this.running)
        requestAnimationFrame(this.updateView);

    }
  }

}
</script>

<style scoped>
.video-container {
  border-radius: 6px;
  width: 100%;
  position: relative;
  overflow: hidden;
  justify-items: center;
  scroll-snap-type: both mandatory;
  aspect-ratio: 1 / 1;
}

video {
  height: 100%;
  width: 100%;
  min-height: 100%;
  min-width: 100%;
  scroll-snap-align: center;
  object-fit: cover;
}

canvas {
  width: 100%;
  height: unset;
  aspect-ratio: 1 / 1;
  border-radius: 6px;
}

.scanner-view {
  position: relative;
}

.scanner-view svg {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
  color: #ffffff73;
}
</style>