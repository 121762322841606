import dayjs from "dayjs";

export class Category {
    id
    name
    validity_start
    validity_end

    static fromJson(json) {
        if (!json)
            return null

        const category = new Category();

        category.id = json.id
        category.name = json.name
        category.validity_start = dayjs(json.validity_start?.date ?? json.validity_start).toDate()
        category.validity_end = dayjs(json.validity_end?.date ?? json.validity_end).toDate()

        return category;
    }
}

export class Ticket {
    id
    category
    barcode
    qrcode
    status
    imported
    updated
    valid
    local_name

    static fromJson(json) {
        if (!json)
            return null

        const ticket = new Ticket();
        ticket.id = json.id
        ticket.category = Category.fromJson(json.category)
        ticket.barcode = json.barcode
        ticket.qrcode = json.qrcode
        ticket.status = json.status
        ticket.imported = dayjs(json.imported?.date).toDate()
        ticket.updated = dayjs(json.updated?.date).toDate()
        ticket.valid = new Date(json.valid?.date)
        ticket.local_name = json.local_name

        return ticket;
    }

    get isValid() {
        return this.status === "scanned" && this.isInValidTimePeriod
    }

    get isValidInFuture() {
        const now = Date.now()
        return this.category.validity_start.getTime() > now;
    }

    get isValidInPast() {
        const now = Date.now()
        return this.category.validity_end.getTime() < now;
    }

    get isInValidTimePeriod() {
        const now = Date.now()

        return this.category
            && this.category.validity_start.getTime() <= now
            && this.category.validity_end.getTime() >= now
    }

    update(ticket) {
        if (ticket.id !== this.id)
            return this;

        this.category = ticket.category
        this.barcode = ticket.barcode
        this.qrcode = ticket.qrcode
        this.status = ticket.status
        this.imported = ticket.imported
        this.updated = ticket.updated
        this.valid = ticket.valid

        return this;
    }
}