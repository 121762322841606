import {HttpClient} from "@/services/api/HttpClient";
import {Ticket} from "@/services/api/models/Ticket";

export default class Tickets extends HttpClient{
    constructor(options) {
        super(options);
    }

    async getById(ticketId){
        const response = await this.getCached(`/v1/tickets?id=${ticketId}`, 15)
        return this.#extractTicket(response)
    }

    async getByQrcode(qrcode){
        const response = await this.getCached(`/v1/tickets?qrcode=${qrcode}`, 15)
        return this.#extractTicket(response)
    }

    async getByBarcode(barcode){
        const response = await this.getCached(`/v1/tickets?barcode=${barcode.toUpperCase()}`, 15)
        return this.#extractTicket(response)
    }

    #extractTicket(result){
        const data = result?.data;

        if(!data){
            throw new Error("No data returned");
        }

        if(!Array.isArray(data) || data.length !== 1){
            throw new Error("No or multiple ticket(s) found");
        }

        return Ticket.fromJson(data[0]);
    }
}