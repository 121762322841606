<template>
  <div class="page">
    <div class="scan">
      <qr-code-scanner @scan="scan"></qr-code-scanner>
      <div id="divider">ou</div>
      <button-input placeholder="Numéro du billet" action="Vérifier" class="shadow" @input.self="onBarcodeInput"></button-input>
    </div>
  </div>
</template>

<script>
import QrCodeScanner from "@/components/Common/TicketInput/QrCodeScanner";
import ButtonInput from "@/components/Common/System/Inputs/ButtonInput";

export default {
  name: "StaffBook",
  data() {
    return {
      ticket: undefined,
      show: undefined
    }
  },
  components: { QrCodeScanner, ButtonInput},
  async mounted() {
    this.show = await this.$api.shows.byId(this.$route.params.id)
    console.log({show: this.show})
  },
  methods: {
    async scan(val) {
      try {
        this.checkTicket(await this.$api.tickets.getByQrcode(val))
      } catch (e) {
        this.checkStatus = 3
        this.$toast.error("Le billet n'existe pas")
      }
    },
    async onBarcodeInput(val) {
      try {
        this.checkTicket(await this.$api.tickets.getByBarcode(val))
      } catch (e) {
        this.checkStatus = 3
        this.$toast.error("Le billet n'existe pas")
      }
    },
    async checkTicket(ticket){
      if(ticket.status === "valid"){
        this.$toast.error("Le billet n'a pas été scanné à l'entrée")
        return;
      }

      if(ticket.status !== "scanned"){
        this.$toast.error("Le billet n'est pas valid")
        return;
      }

      if(!ticket.isInValidTimePeriod){
        this.$toast.error("Le billet n'est pas valide aujourd'hui")
        return;
      }

      this.ticket = ticket;

      const result = await this.$booking.book([this.ticket], this.$route.params.id);
      if(result) {
        if(this.show.bookingType === 2) {
          const booking = await this.$api.bookings.byBarcode(this.ticket.barcode, true)
          const cn = booking.find(b => b.barcode === this.ticket.barcode)?.call_number;
          if(cn){
            this.$toast.warning(`Activité avec ticket: transmettre au visiteur le numéro <b>${cn}</b>`, {
              duration: 30000,
            })
          }
        }

        await this.$router.push(`/staff/planning/${this.$route.params.id}`)
      }
    }
  }
}
</script>

<style scoped>
.page {
  padding: 20px;
  height: 100%;
  z-index: 100;
}

#divider {
  color: black;
}

#divider:after, #divider:before {
  background-color: black;
}

</style>