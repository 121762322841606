<template>
  <alert-base color="blue">
    <template v-slot:icon>
      <information></information>
    </template>
    <template v-slot:content>
      <slot></slot>
    </template>
  </alert-base>
</template>

<script>
import AlertBase from "@/components/Common/System/Alerts/Base";
import {Information} from "mdue"

export default {
  name: "AlertInfo",
  components: {AlertBase, Information}
}
</script>

<style scoped>

</style>