import {HttpClient} from "@/services/api/HttpClient";
import axios from "axios";

export default class Queues extends HttpClient {
    config

    constructor(options, config) {
        super(options);
        this.config = config;
    }

    async all() {
        return await axios
            .get(`https://eventws.ch/apps/screen/ajax/queues.php`, HttpClient.config())
    }

    async setShow(showId, queueId) {
        if(!showId){
            return await axios
              .post(`https://eventws.ch/apps/screen/ajax/queues_set_show.php?queue=${queueId}`, HttpClient.configCors())
        }

        return await axios
            .post(`https://eventws.ch/apps/screen/ajax/queues_set_show.php?show=${showId}&queue=${queueId}`, HttpClient.configCors())
    }

}