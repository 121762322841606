<template>
  <alert-base v-if="!global.online" color="orange" square>
    <template v-slot:icon>
      <cloud-off-outline></cloud-off-outline>
    </template>
    <template v-slot:content>
      Tu n'est pas connecté à internet

    </template>
  </alert-base>
  <div class="box">
    <div class="view">
      <router-view></router-view>
    </div>
    <ul ref="menu" class="menu" :style="{ 'mask-position': maskLeft }">
      <li>
        <router-link to="/staff/scan">
          <QrcodeScan class="icon "></QrcodeScan>
          <QrcodeScan class="icon icon-active"></QrcodeScan>
          <span>Scan</span>
        </router-link>
      </li>
      <li>
        <router-link to="/staff/planning">
          <CalendarTextOutline class="icon"></CalendarTextOutline>
          <CalendarText class="icon icon-active"></CalendarText>
          <span>Activités</span>
        </router-link>
      </li>
      <li>
        <router-link to="/staff/queue">
          <HumanQueue class="icon"></HumanQueue>
          <HumanQueue class="icon icon-active"></HumanQueue>
          <span>Queue</span>
        </router-link>
      </li>
      <li>
        <router-link to="/staff/infos">
          <HelpCircleOutline class="icon"></HelpCircleOutline>
          <HelpCircle class="icon icon-active"></HelpCircle>
          <span>Aide</span>
        </router-link>
      </li>
    </ul>
  </div>

</template>
<script>
import {
  CloudOffOutline,
  CalendarText,
  CalendarTextOutline,
  QrcodeScan,
  HelpCircle,
  HelpCircleOutline,
  HumanQueue,
} from 'mdue'

import {global} from "@/stores/global";
import AlertBase from "@/components/Common/System/Alerts/Base";

export default {
  name: "Staff",
  data() {
    return {
      global,
      maskLeft: '-1178px -2px',
      maskLeftBase: -1225,
    }
  },
  mounted(){
    this.updateDrop();
    window.addEventListener("resize", this.updateDrop);
  },
  unmounted() {
    window.removeEventListener("resize", this.updateDrop);
  },
  methods:{
    updateDrop() {
      setTimeout(() => {
        const activeLink = this.$refs.menu.querySelector(".router-link-exact-active")
        if(activeLink == null)
          return

        const boundingBox = activeLink.getBoundingClientRect();

        this.maskLeft = (this.maskLeftBase + boundingBox.left + boundingBox.width / 2) + 'px -2px'
      }, 30)
    },
  },
  components: {
    CalendarText,
    CalendarTextOutline,
    AlertBase,
    CloudOffOutline,
    QrcodeScan,
    HelpCircle,
    HelpCircleOutline,
    HumanQueue
  },
  watch:{
    $route (){
      this.updateDrop();
    }
  }
}
</script>

<style scoped>

.box {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}

.view{
  height: calc(100% - 50px);
}

.menu {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  padding: 0;
  margin: 0;

  background: linear-gradient(47deg, #432ed1 0%, #2e35d1 100%);

  width: 100%;
  height: 60px;

  mask-image: url('~@/assets/droplet_neg.svg');
  mask-size: 2480px 67px;

  mask-repeat: no-repeat;
  transition: mask-position ease-in-out .2s;
}

@media only screen and (min-width: 720px) {
  .box {
    display: grid;
    grid-template-rows: 1fr 80px;
    grid-template-columns: minmax(0, 1fr);
  }

  .menu {
    mask-image:none;

    position: relative;
    height: 100%;
    display: flex;
    padding: 0;
    margin: 0;
  }
}


.menu li {
  width: 100%;
  color: white;
  list-style: none;
  margin-top: 5px;
}

.menu a {
  text-decoration: none;
  color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.menu a span {
  font-size: 0.9em;
}

.menu .icon {
  font-size: 1.6em;
  display: block;
}

.menu .icon.icon-active {
  display: none;
}


.menu .router-link-active span {
  font-weight: 700;
}

.menu .icon-active {
  display: none;
}

.menu .router-link-active .icon {
  display: none;
}

.menu .router-link-active .icon-active {
  display: block;
}
</style>