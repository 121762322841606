import {HttpClient} from "@/services/api/HttpClient";

export default class Bookings extends HttpClient{
    constructor(options) {
        super(options);
    }

    async book(activityId, barcodes = []){
        return await this.post(`/v1/activities/${activityId}/book`, barcodes)
    }

    async unBook(activityId, barcode){
        const response = await this.delete(`/v1/bookings`, {
            barcode: barcode,
            show_id: activityId
        })
        return response
    }

    async byBarcode(barcode, nocache = false){
        const response = await this.getCached(`/v1/bookings?barcode=${barcode}`, nocache ? 0:10)
        return Bookings.#getData(response)
    }

    async checkin(bookingId) {
        return await this.post(`/v1/bookings/${bookingId}/checkin`)
    }

    static #getData(result){
        const data = result?.data;

        if(!data){
            throw new Error("No data returned");
        }

        return data;
    }


}