<template>
  <div class="multi-select">
    <label v-for="item in items" :key="item">
      <input type="checkbox" :value="item" v-model="checkedItems" @change="change($event)">
      <div class="checkbox"><check-bold class="tick"></check-bold></div>
      <span>
        <slot v-bind:item="item"></slot>
      </span>
    </label>
  </div>
</template>

<script>

import { CheckBold } from "mdue";

export default {
  name: "MultiSelect",
  components: {
    CheckBold
  },
  props: ["items"],
  emits: ["change"],
  data() {
    return {
      checkedItems: []
    }
  },
  methods: {
    change() {
      //this.$emit("change", this.checkedItems)
    }
  },
  watch: {
    checkedItems(val) {
      this.$emit("change", val.filter(() => true))
    }
  }
}
</script>

<style scoped>
  .multi-select {
    display: flex;
    flex-direction: column;
    color: black;
    font-family: 'Fira Mono', monospace;
    font-weight: bold;
    text-transform: capitalize;
  }

  .multi-select input{
    display: none;
  }

  .multi-select > label{
    display: flex;
    margin: 10px 0;
    align-items: center;
    column-gap: 10px;
    cursor: pointer;
    border-bottom: 1px solid rgba(118, 67, 214, 0.56);
    padding-bottom: 10px;
    margin-bottom:0;
  }

  .multi-select > label:last-child{
    border-bottom: none;
    padding-bottom: 0px
  }

  .multi-select .checkbox{
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .multi-select label:hover  input ~ .checkbox {
    background-color: #ccc;
  }

  .multi-select .checkbox{
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
  }

  .checkbox .tick{
    display: none;
    color: white;
  }

  .multi-select label input:checked ~ .checkbox {
    background-color: #7643D6;
  }

  .multi-select input:checked ~ .checkbox .tick{
    display: block;
  }

</style>