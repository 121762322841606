<template>
  <alert-base color="green">
    <template v-slot:icon>
      <check-circle></check-circle>
    </template>
    <template v-slot:content>
      <slot></slot>
    </template>
  </alert-base>
</template>

<script>
import AlertBase from "@/components/Common/System/Alerts/Base";
import {CheckCircle} from "mdue"

export default {
  name: "AlertSuccess",
  components: {AlertBase, CheckCircle}
}
</script>

<style scoped>

</style>