<template>
  <div class="page">
    <div class="logo">
      <img src="@/assets/logo.svg">
    </div>
    <div class="scrollable">
      <div class="pannel" v-html="html" v-if="html.trim() !== ''">
      </div>
      <div class="pannel" v-if="isStaff">
        <h2>Staff</h2>
        <button @click="$router.push('/staff')">Application staff</button>
      </div>
      <div class="pannel faq">
        <h2>Aide</h2>
        <collapsable title="Stands d'information">
          Les stands infos sont présent à l'entrée de la halle XXL et de la halle Ouest
        </collapsable>
        <collapsable title="Réservation">
          Il est nécéssaire de réserver les activités ayant l'icône
          <Ticket></Ticket>
          Les autres activités sont accessibles sans réservation.
        </collapsable>
        <Collapsable title="Enfant moins de 9 ans">
          Rendez-vous à un Stand info pour recevoir un code QR.
        </Collapsable>
        <collapsable title="Impossible de scanner le QR Code">
          Vous pouvez saisir le numéro présent sur le billet dans le champ "Numéro du billet".
        </collapsable>
        <Collapsable title="Utiliser plusieurs billets">
          Dans l'onglet Billets, utilisez l'icône
          <plus></plus>
          puis scanner le QR Code ou saisissez le numéro.
        </Collapsable>
        <Collapsable title="Annuler une réservation">
          Dans l'onglet Billets, cliquer sur l'icône
          <TrashCanOutline></TrashCanOutline>
          de la réservation.
        </Collapsable>
        <collapsable title="Ajout de billet impossible">
          Rendez-vous à un stand info.
        </collapsable>
        <Collapsable title="QR Code trop petit">
          Vous pouvez saisir le numéro présent sur le billet dans le champ "Numéro du billet".
        </Collapsable>
        <Collapsable title="Billet non imprimé">
          Vous pouvez saisir le numéro présent sur le billet dans le champ "Numéro du billet".
        </Collapsable>
        <Collapsable title="Favori">
          Il est possible d'enregister une activité en cliquant sur l'icône <StarOutline></StarOutline>
        </Collapsable>
        <collapsable title="Pas de réponse ?">
          Rendez-vous à un stand info, nous nous ferons un plaisir de trouver une solution.
        </collapsable>
      </div>
      <div class="pannel">
        <h2>Charte POLYMANGA</h2>
        <p>
          POLYMANGA est un espace de partage et de découverte ouvert à tou·x·t·e·s. Pour que tout le monde puisse
          profiter un maximum de cet espace et de ce qu’il propose, il nécessite l’attention et le respect de
          chacun·e·x.
        </p>
        <p>
          En profitant de la convention, je m’engage à adopter un comportement responsable et respectueux envers
          l’espace, les personnes et le matériel qui font la convention. Cela veut dire&nbsp;:
        </p>
        <ol>
          <li>
            <p><b>Je respecte, et mérite le respect de, tous les participants du POLYMANGA&nbsp;:</b></p>
            <p>
              Visiteurs, cosplayeurs, exposants, animateurs, invités, staff, employés (équipe de nettoyage, sécurité,
              agents VIP), je traite chacun·e·x avec politesse et ouverture.
            </p>
          </li>
          <li>
            <b>Je ne réalise pas de paroles, gestes, comportements</b> sexistes, homophobes, transphobes, racistes,
            validistes, agistes, islamophobes, antisémites, ni quelconque autre forme de <b>discrimination et de
            stigmatisation</b>, portant atteinte à la personne, ce qu’elle porte, ce qu’elle exprime, ce qu’elle
            représente, ou ce qui en est présumé.
          </li>
          <li>
            <p><b>Je respecte l’espace personnel et le corps de chacun·e·x</b></p>
            <p>
              Je n’aurai pas de propos ou gestes intimidants, harcelants, malveillants. Je n’aurai pas de propos ou de
              geste rapprochés et intimes avec une personne qui ne m’a pas donné son consentement (contacts,
              rapprochements, attouchements, frottements, taquineries, blagues, insinuations, photos…)
            </p>
          </li>
          <li>
            <p>En plus de couvrir mon postérieur et mes génitaux, <b>je veille à porter un vêtement sur mon buste, même
              cosplayé.</b></p>
          </li>
          <li>
            <p><b>Je ne prends pas en photos une personne, </b>
              ou un groupe de personnes (visiteurs, cosplayeurs, exposants, animateurs, invités, VIP, staff, personnel)
              sans demander l’accord au préalable&nbsp;!
            </p>
          </li>
          <li>
            <p>
              <b>Je réflechis avant de demander les contacts </b>
              de quelqu’un si c’est approprié et pertinent. Si la personne ne veut pas, j’accepte sa réponse et je la
              laisse tranquille.
              Je fais attention avant de donner mes données personnelles (prénom, nom, âge, adresse…) et mes contacts
              (numéro de téléphone, profil réseaux sociaux,…) à une personne que je ne connais pas.
            </p>
          </li>
          <li>
            <p>
              <b>Je respecte les espaces, biens et matériels</b>
              mis à ma disposition ou à ma portée durant toute la convention (tables, stands, marchandises, consoles de
              jeux, sièges, pancartes, toilettes, etc).
            </p>
          </li>
          <li>
            <p><b>Nous sommes tou·x·t·e·s responsable et pouvons tou·x·t·e·s faire la différence. </b>
              L’espace de la convention se veut et s’espère suffisamment sécuritaire et confortable pour tou·x·t·e·s.
            </p>
          </li>
        </ol>
        <p>
          Si je suis témoin d’une situation désagréable, violente, discriminante, ou encore si je remarque du matériel
          manquant, abimé ou un espace dangereux (ex. sol glissant, fissuré, chaise cassée, accès compliqué ou
          impossible), je peux le signaler au staff. Celui-ci le fera remonter plus haut dans l’organisation, pour que
          la situation change.
        </p>
      </div>
      <div class="pannel">
        <h2>Cosplay is not consent!</h2>
        <p>
          Lae cosplayeur (et autre personne costumée) ne te doit rien. Hormis le même respect que tu lui donneras.
        </p>
        <ul>
          <li>Réfléchis avant de donner un compliment ou un conseil non sollicité.</li>
          <li>Réfléchis avant de lui demander ses contacts. Et si tu les demandes et que la personne ne veut pas,
            accepte sa réponse et laisse-la tranquille.
          </li>
          <li>N’oublie pas que c’est une vraie personne derrière son costume. Ne touche pas la personne. Respecte son
            espace personnel.
          </li>
          <li>Demande-lui son accord avant de prendre une photo.</li>
        </ul>
      </div>
      <div class="pannel">
        <h2>Outils</h2>
        <p>Version: {{version}}</p>
        <p class="update" @click="update"><a href="#none">Réinitialiser l'application</a></p>
      </div>
    </div>
  </div>
</template>

<script>
import {PersistentStore} from "@/services/PersistentStore";
import Collapsable from "../Common/Collapsable";
import {TrashCanOutline, Plus, Ticket, StarOutline} from "mdue"

export default {
  name: "Infos",
  components: {
    Collapsable,
    TrashCanOutline,
    Plus,
    Ticket,
    StarOutline,
  },
  data() {
    return {
      isStaff: false,
      html: "",
      version: PersistentStore.version
    }
  },
  mounted() {
    this.isStaff = PersistentStore.isStaffLoggedIn;
    this.$api.infos.public().then((html) => this.html = html);
  },
  methods: {
    update() {
      location.reload(true)
      this.$api.clear()
    }
  }
}
</script>

<style scoped>
.pannel {
  margin: 20px;
  padding: 20px;
  background-color: white;
  border-radius: 6px;
  box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;

}

ul, ol {
  padding-left:23px;
}

.pannel:last-child {
  margin-bottom: 80px;
}

.pannel h2, .pannel h1 {
  margin-top: 0;
  margin-bottom: 10px;
}

.logo {
  height: calc(100% - 16px);
  width: calc(100% - 16px);
  background: linear-gradient(47deg, #9043d6 0%, #6643d6 100%);
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo img {
  height: auto;
  max-width: calc(100% - 40px);
  max-height: calc(100% - 20px);
  filter: invert(100%);
}


.page {
  display: grid;
  grid-template-rows: 70px 1fr;
  height: 100%;
}

.scrollable {
  height: 100%;
  overflow-x: auto;
}

::-webkit-scrollbar {
  width: 8px;
  border-radius: 0px !important;
}

::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #7542d5;
}

.update {

}

.faq > * {
  margin-top: 10px;
}

</style>
