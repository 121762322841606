<template>
  <alert-base color="red">
    <template v-slot:icon>
      <alert></alert>
    </template>
    <template v-slot:content>
      <slot></slot>
    </template>
  </alert-base>
</template>

<script>
import AlertBase from "@/components/Common/System/Alerts/Base";
import {Alert} from "mdue"

export default {
  name: "AlertError",
  components: {AlertBase, Alert}
}
</script>

<style scoped>

</style>