import {HttpClient} from "@/services/api/HttpClient";

export default class Infos extends HttpClient{
    constructor(options) {
        super(options);
    }

    async staff(){
        const response = await this.getCached(`/v1/infos/staff`, 90)
        return response.data ?? ""
    }

    async public(){
        const response = await this.getCached(`/v1/infos/public`, 90)
        return response.data ?? ""
    }

    async version(){
        const response = await this.getCached(`/v1/infos/version`, 300)
        return response.data ?? ""
    }


    async on(){
        const response = await this.getCached(`/v1/infos/on`, 20)
        return response.data == '1'
    }

    async bookingTimeOffset(){
        const response = await this.getCached(`/v1/infos/booking_time_offset`, 20)
        if(isNaN(response.data)){
            return 60 // 1h by default
        }

        return Number(response.data)
    }
}