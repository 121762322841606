<template>
  <div class="progress-track" v-if="global.isLoading()">
    <div class="progress-elem unknown"></div>
  </div>
</template>

<script>
import {global} from "@/stores/global";

export default {
  name: "Loading",
  data() {
    return{
      global,
    }
  },
  components: {

  }
}
</script>

<style>
  .progress-track{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
  }

  .progress-elem{
    background-color: #7643D6;
    height: 100%;
    width: 0;
  }

  .progress-elem.unknown{
    animation-name: unknown-progress;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  @keyframes unknown-progress {
    0%   {
      transform: translateX(-30vw);
      width: 30vw;
    }
    50%{
      width: 30vw;
      /*transform: translateX(35vw);*/
    }
    100% {
      transform: translateX(100vw);
      width: 30vw;
    }
  }
</style>