<template>
  <div class="collapsable"  @click="open = !open">
    <h3>
      <chevron-down v-if="!open"></chevron-down>
      <chevron-up v-if="open"></chevron-up>
      <span>{{title}}</span>
    </h3>
    <div class="content" v-if="open">
      <slot></slot>
    </div>
  </div>
</template>

<script>

import {ChevronDown,ChevronUp} from 'mdue'

export default {
  name: "Collapsable",
  props: ["title"],
  components: {
    ChevronDown,
    ChevronUp
  },
  data() {
    return{
      open: false
    }
  }
}
</script>

<style scoped>
.collapsable{
  background-color: #efefef;
  padding: 10px;
  border-radius: 6px;
}
h3{
  margin: 0;
  display: flex;
  align-items: center;
  font-size: 1em;
}

h3 svg{
  font-size: 1.3em;
}

.content{
  margin-top:5px;
}
</style>