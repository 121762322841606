<template>
  <div class="button-input-group">
    <input v-model="inputValue" v-bind:placeholder="placeholder">
    <button @click="clicked">{{ action || 'Valider' }}</button>
  </div>

</template>

<script>
export default {
  name: "button-input",
  props: ['value', 'placeholder', 'action'],
  data(){
    return{
      inputValue: "",
    }
  },
  mounted() {

  },
  methods: {
    clicked() {
      this.$emit('input', this.inputValue)
    },
  },
  watch: {
    value(newVal) {
      this.inputValue = newVal
    }
  },
}
</script>

<style scoped>
  .button-input-group{
    width: 100%;
    display: flex;
  }


  input{
    border-left: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    flex-grow: 1;
    min-width: 0px;
  }

  button{
    padding: 16px;
    font-size: 1.2em;
    border: 0;
    border-radius: 0 6px 6px 0;
    cursor: pointer;
    min-width: fit-content;
    margin: 0;
    background: none;
    background-color: #d5d5d5;
    color:#1e1e1e;
  }

  button:hover{
    background: #cecece;
  }
</style>