<template>
  <template v-if="Array.isArray(bookings)">
    <div  v-for="booking in bookings" :key="booking.barcode" class="booking">
      <h3>{{booking.show.name}}
        <button class="unbook" @click="remove" :value="booking.barcode+','+booking.show.id" v-if="!booking.scanned_in">
          <trash-can-outline></trash-can-outline>
        </button>
      </h3>
      <div>{{salles?.find(s => s.id === booking.show?.salle)?.name}}</div>
      <div v-if="booking.show.booking_type === 2">
        Votre numéro:
        <span class="call_number">{{booking.call_number}}</span>
        <span class="called" v-if="booking.called === 1"> Appelé</span>
      </div>
      <div class="details">{{this.formatHour(booking.show.start)}} - {{this.formatHour(booking.show.end)}}</div>
    </div>
    <alert-info v-if="!ticket.isValid && ticket.isValidInFuture">Le billet n'est pas encore valide.</alert-info>
    <alert-info v-if="ticket.isValidInPast && ticket.status === 'scanned'">
      Le billet n'est plus valide.<br>
      Catégorie: {{ticket.category.name}}
    </alert-info>
    <alert-warning v-if="ticket.status === 'cancelled'">
      Le billet est annulé
    </alert-warning>

    <button class="book" @click="$router.push('/planning')" v-if="canBook">Reserver</button>
  </template>

  <Confirm show="true" ref="confirm" @validate="validateRemove">Voulez-vous vraiment annuler cette reservation ?</Confirm>
</template>

<script>

import Confirm from "@/components/Common/Dialog/Confirm";

import { TrashCanOutline } from "mdue";
import AlertInfo from "@/components/Common/System/Alerts/Info";
import AlertWarning from "@/components/Common/System/Alerts/Warning";

export default {
  name: "Bookings",
  components: {AlertWarning, AlertInfo, Confirm, TrashCanOutline},
  props: ["ticket"],
  data() {
    return {
      bookings: undefined,
      salles: []
    }
  },
  async mounted() {
    this.salles = await this.$api.salles.all();
  },
  methods: {
    async validateRemove(item){
      const result = await this.$api.bookings.unBook(item.show, item.barcode)
      if(result.status === 200){
        this.$toast.success("Réservation annulée")
      }
      await this.refreshBookings(this.ticket, true)
    },
    remove(event){
      const parts = event.target.value.split(',')
      if(parts.length !== 2)
        return;
      this.$refs.confirm.show({show: parts[1], barcode: parts[0]});
    },
    formatHour(date){
      return this.$dayjs(date.date).format("HH[h]mm");
    },
    async refreshBookings(ticket, nocache = false) {
      this.bookings = (await this.$api.bookings.byBarcode(ticket.barcode, nocache))
          .filter(b => !b.scanned_in)
    },
  },
  computed: {
    canBook() {
      if(!this.ticket.isValid || !this.ticket.valid)
        return false;

      return this.bookings.length === 0 || this.bookings.every(b => b.scanned_in )
    }
  },
  watch: {
    ticket: {
      immediate: true,
      async handler (val, oldVal) {
        if(val?.barcode === oldVal?.barcode){
          return;
        }
        await this.refreshBookings(val, true);
      }
    }
  }

}
</script>

<style scoped>
  .booking{
    background: linear-gradient(47deg, #9043d6, #6643d6);
    padding: 20px;
    border-radius: 6px;
    margin-bottom: 20px;
  }

  .booking h3 {
    font-size: 1.17em;
  }

  .booking > * {
    margin: 0;
    color: white;
  }

  .booking .details{
    margin-top: 0px;
  }

  .book{
    width: 100%;
  }

  .call_number{
    font-weight: bold;
  }

  .called{
    font-weight: bold;
  }

  .unbook {
    padding: 0;
    margin: 0;
    font-size: 1.2em;
    background: none;
  }

  h3{
    display: flex;
    justify-content: space-between;
  }

  .unbook svg{
    pointer-events: none;
  }
</style>