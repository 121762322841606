<template>
  <div class="container">
    <img class="logo" src="@/assets/logo.svg">
    <h1>Bienvenue à Polymanga 2024 !</h1>
    <p>Vous pouvez réserver votre place pour les dédicaces de votre choix et ainsi éviter de patienter dans les files d’attentes ! Une fois votre place assurée, vous profitez du festival et venir sur place quelques minutes avant le début pour entrer. Scannez, réservez, profitez !</p>
  </div>
  <div class="actions">
    <router-link class="next" to="/in-boarding/add-qr">Scanner mon billet</router-link>
    <router-link class="skip" to="/planning" @click="PersistentStore.skipInBoarding = true">Continuer sans billet</router-link>
  </div>
</template>

<script>
import {PersistentStore} from "@/services/PersistentStore";

export default {
  name: "About",
  data() {
    return {
      PersistentStore
    }
  },
  components: {
  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style scoped>
h1{
  font-size: min(6vw, 2em);
}
.container {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 10px 20px;
}

.logo{
  width: 100%;
  display: block;
  filter: grayscale(1) invert(1);
}

.container > *{
  margin: 0;
}

.actions{
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding: 10px 20px;
}

</style>