<template>
  <div class="bg">
    <div class="container">
      <ticket-input @scan="onScan"></ticket-input>
    </div>
    <div class="actions">
      <router-link :class="{disabled: !canContinue}"  class="next" to="/tickets" @click="persist">Enregistrer</router-link>
      <router-link :class="{disabled: !canContinue}"  class="cancel" to="/tickets">Fermer</router-link>
    </div>
  </div>

</template>

<script>
import {PersistentStore} from "@/services/PersistentStore";
import TicketInput from "@/components/Common/TicketInput/TicketInput";

export default {
  name: "AddTicket",
  data() {
    return {
      ticket: null,
    };
  },
  components: {
    TicketInput,
  },
  methods: {
    onScan(ticket){
      this.ticket = ticket
    },
    persist(){
      PersistentStore.addTicket(this.ticket)
    }
  },
  computed: {
    canContinue(){
      return !!this.ticket
    }
  }
}
</script>

<style scoped>
.bg{
  background-color: rgba(106, 60, 194, 0.9);
  width: calc(100% - 40px);
  margin: 20px;
  border-radius: 6px;
  color: white;
  padding: 10px 0;
}

.container {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 10px 20px;

}

.actions{
  display: flex;
  flex-direction: row-reverse;
  padding: 10px 20px;
  column-gap: 20px;
}

</style>