import {HttpClient} from "@/services/api/HttpClient";

export default class Salles extends HttpClient{
    constructor(options) {
        super(options);
    }

    async all(){
        const response = await this.getCached(`/v1/salles`, 30)
        return Salles.#getData(response)
    }

    async bySlug(slug){
        const response = await this.get(`/v1/salles/${slug}`)
        return Salles.#getData(response)
    }

    async allowed(){
        return (await this.get(`/v1/auth/salles`))?.data?.salleIds
    }


    static #getData(result){
        const data = result?.data;

        if(!data){
            throw new Error("No data returned");
        }

        return data;
    }
}