import Salles from "@/services/api/Salles";
import Shows from "@/services/api/Shows";
import Tickets from "@/services/api/Tickets";
import Bookings from "@/services/api/Bookings";
import Infos from "@/services/api/Infos";
import Queues from "@/services/api/Queues";
import {PersistentStore} from "@/services/PersistentStore";

export default class Api{
    #app
    url
    config

    constructor(apiUrl, config) {
        this.url = apiUrl;
        this.config = config;
    }


    install (app) {
        this.#app = app
        app.$api = app.config.globalProperties.$api = Api.build(app, this.url, this.config);
    }

    static build(app, url, config) {
        const options = {
            baseURL: url,
            app: app,
        }

        return {
            salles: new Salles(options),
            shows: new Shows(options, config.ShowConfiguration),
            tickets: new Tickets(options),
            bookings: new Bookings(options),
            infos: new Infos(options),
            queues: new Queues(options),
            clear() {PersistentStore.clearMatching("/v1/")}
        };
    }
}