<template>
  <loading></loading>
  <router-view v-if="!locked"></router-view>
  <div class="locked" v-if="locked">
    <img class="logo" src="@/assets/logo.svg">
    <h2>Application verouillée</h2>
  </div>
</template>

<script>

import Loading from "@/components/Common/System/Loading/Loading";
import {PersistentStore} from "@/services/PersistentStore";

export default {
  name: 'App',
  components: {
    //InBoarding,
    Loading
  },
  data() {
    return {
      locked: true,
      intervalId: undefined
    }
  },
  async mounted() {
    this.locked = !await this.$api.infos.on() && !PersistentStore.isStaffLoggedIn
    this.intervalId = window.setInterval(async () => {
      this.locked = !await this.$api.infos.on() && !PersistentStore.isStaffLoggedIn
    }, 30000);
  },
  unmounted() {
    if(this.intervalId)
      clearInterval(this.intervalId);

    this.intervalId = undefined
  },
  methods: {
    edit() {
    }
  },
  watch:{
    async $route(){
      if(this.$route?.query?.token?.length === 32) {
        PersistentStore.staffToken = this.$route.query.token;

        if(PersistentStore.isStaffLoggedIn){
          PersistentStore.allowedSalles = await this.$api.salles.allowed();
          await this.$router.push("/staff/scan");
        }
      }
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Fira+Mono|Fira+Sans&display=swap');
#app {
  font-family: 'Fira Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 0px;
}

.locked{
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;

  background: linear-gradient(47deg, #9043d6 0%, #6643d6 100%);

  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.locked > img{
  max-width: calc(100vw - 100px);
}

img.logo {
  filter: invert(100%);
}

.locked > h2{
  color: white;
  text-align: center;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

</style>

