import { reactive } from 'vue'

export const global = reactive({
    loadingCount: 0,
    online: window.navigator.onLine,
    isLoading() {
        return this.loadingCount > 0
    },
    addLoading() {
        this.loadingCount ++;
    },
    removeLoading() {
        this.loadingCount--;
        if(this.loadingCount < 0)
            this.loadingCount = 0
    },
})

window.addEventListener('online', () => global.online = window.navigator.onLine)

window.addEventListener('offline', () => global.online = window.navigator.onLine)