<template>
  <div class="page">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "Book",
}
</script>

<style scoped>
.page{
  /*background-color: #7643D6;*/
  color: white;
  width: 100%;
  height: calc(100% - 10px);
  margin: 0;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-top: 10px;
  background: center left url(/img/wallpaper-min.png), #6135b3;
  background-size: cover;
  background-blend-mode: soft-light;
}
</style>