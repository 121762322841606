export class DateTimeZone {
    date
    timezone
    timezoneType

    constructor(date, timezone, timezoneType) {
        this.date = date;
        this.timezone = timezone;
        this.timezoneType = timezoneType;
    }

    static fromJson(json) {
        if(!json)
            return null;

        return new DateTimeZone(json.date, json.timezone, json.timezoneType);
    }
}