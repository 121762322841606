<template>
  <div class="card" :class="{ flush: item.isFlush, full: item.isFull || (item.capacity !== null && item.capacity <= item.bookings), canceled: item.isCanceled }" @click="book">
    <div class="header">
      <h3>{{ name }}</h3>
      <div>
        <ticket class="book-icon" v-if="canBook"></ticket>
        <Star v-if="isFavorite" @click.stop="toggleFavorite" class="favorite-icon"></Star>
        <StarOutline v-if="!isFavorite" @click.stop="toggleFavorite" class="favorite-icon"></StarOutline>
      </div>

    </div>
    <div><i>{{salle?.name}}</i></div>
    <div>{{item.description}}</div>
    <div class="line">
      <span class="date">
        {{ displayedDate }}
        <span v-if="item.delay > 0" class="late">+{{ this.item.delay }}min</span>
      </span>
      <span class="capacity" v-if="!item.isFlush && !staff && item.capacity !== null">
        {{ item.capacity }} places
      </span>
    </div>
    <div class="line">
      <span class="fillingStatus">
        <CountDown :finished-text="displayFillingStatus" :target-date="openDate"></CountDown>
      </span>
    </div>
  </div>
</template>

<script>

import {Ticket, Star, StarOutline} from 'mdue'
import {global} from "@/stores/global";
import CountDown from "@/components/Common/CountDown/CountDown.vue";
import {PersistentStore} from "@/services/PersistentStore";


export default {
  name: "PlanningItem",
  components: {CountDown, Ticket, Star, StarOutline},
  props: ["staff", "item", "salles"],
  data() {
    return {
      global,
      isFavorite: false,
      openDate: null,
      bookingTimeOffset: 120,
    }
  },
  async mounted() {
    this.bookingTimeOffset = await this.$api.infos.bookingTimeOffset();

    this.isFavorite = PersistentStore.isFavoriteShow(this.item.id)

    if(this.item.start && this.canBook){
      const startDate = this.$dayjs(this.item.start.date)
      this.openDate = startDate.subtract(this.bookingTimeOffset, 'minute')
    }

  },
  methods: {
    book() {
      if(this.openDate > this.$dayjs()){
        this.$toast.warning(`La réservation sera possible à paritir de ${this.openDate.format('dddd HH[h]mm')}`);
        return;
      }

      if(this.staff){
        this.$router.push(`/staff/planning/${this.item.id}/`);
        return;
      }

      if (this.canBook) {
        this.$router.push(`/book/${this.item.id}/tickets/`);
      }
    },
    toggleFavorite(){
      this.isFavorite = !this.isFavorite

      if(this.isFavorite){
        PersistentStore.addFavoriteShow(this.item.id)
      } else {
        PersistentStore.removeFavoriteShow(this.item.id)
      }
    }
  },
  watch: {
    salles(newVal) {
      console.log(newVal, this.salles)
    }
  },
  computed: {
    shortDesc() {
      const desc = this.item.description;
      if(desc.length < 80)
        return desc;

      return desc.substring(0, 77) + "..."
    },
    displayedDate() {
      const start = this.$dayjs(this.item.start.date).format("HH[h]mm");
      const end = this.$dayjs(this.item.end.date).format("HH[h]mm");

      return `${start} - ${end}`
    },

    salle() {
      return this.salles.find(s => s.id === this.item.salle)
    },
    name() {
      if (this.item.isFlush) {
        return "Vidage"
      }

      return this.item.name
    },
    displayFillingStatus() {
      const shows = this.$api.shows;

      if(this.item.capacity === null){
        return "Entrée libre"
      }

      if(this.staff){
        return shows.provideShowFillingStatusStaff(this.item)
      } else {
        return shows.provideShowFillingStatus(this.item);
      }


    },
    canBook() {
      const shows = this.$api.shows;

      return shows.canBook(this.item)
          && global.online;
    }
  },
}
</script>

<style scoped>
.card {
  width: calc(100% - 40px);
  padding: 20px;
  background: #fff;
  color: #2c3e50;
  border-radius: 6px;
}

h3 {
  margin: 0;
}

p {
  margin: 4px 0;
}

.flush {
  background-color: black;
  color: white;
}

.full, .canceled {
  /*background-color: #d33935;
  color: white;*/
}

.full .fillingStatus {
  color: #d33935;
}

.late {
  font-weight: bold;
}

.capacity {
  font-family: 'Fira Mono', monospace;
}

.fillingStatus {
  font-family: 'Fira Mono', monospace;
}

.card > :last-child {
  margin-bottom: 0;
}

.line {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  align-items: center;
  text-align: center;
}
.line > span{
  /*flex: 1;*/
}

.book-icon {
  color: #2c3e50;
  font-size: 1.6em;
}
.favorite-icon {
  color: #2c3e50;
  font-size: 1.6em;
}

.header {
  display: flex;
  justify-content: space-between;
}
</style>