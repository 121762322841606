<template>
  <div class="page shadow" v-if="show">
    <h2>{{show.name}}</h2>
    <p>{{show.description}}</p>


    <button @click="bookForVisitor"  v-if="show.bookingType === 1 || show.bookingType === 2">
      Réserver pour un visiteur
    </button>
    <template v-if="canAdmin">
      <button @click="endScanning" v-if="show.scanning">Terminer le scanning</button>
      <button @click="startScanning" v-if="!show.scanning">Commencer le scanning</button>
      <button @click="nextTicket" v-if="show.bookingType === 2">Appeler le prochain ({{next}})</button>
    </template>

    <confirm ref="confirm" @validate="closeBookings" title="Confirmation">
      Voulez-vous vraiment fermer les reservations ?
      L'activité ne sera plus disponible aux visiteurs
    </confirm>

    <button class="outline" @click="$router.push('/staff/planning')">Retour</button>

  </div>
  <div class="page shadow" v-if="show">
    <h3>Statistiques des réservations</h3>
    <table>
      <tr>
        <th>Capacité</th>
        <th>Réservations</th>
        <th>Scanné</th>
      </tr>
      <tr>
        <td>{{show.capacity}}</td>
        <td>{{show.bookings}} ({{Math.round(show.bookings / show.capacity * 100)}}%)</td>
        <td>{{show.scanned}} ({{Math.round(show.scanned / show.capacity * 100)}}%)</td>
      </tr>
    </table>
  </div>
</template>

<script>

import Confirm from "@/components/Common/Dialog/Confirm";
import {PersistentStore} from "../../../services/PersistentStore";

export default {
  name: "ActivityDetail",
  components: {
    Confirm
  },
  data() {
    return{
      show: undefined,
      showId: undefined,
      canAdmin: false,
      next: "",
    }
  },
  async mounted() {
    this.showId = this.$route.params.id;
    this.updateShow()
  },
  methods: {
    async closeBookings() {
      await this.$api.shows.close(this.showId)
      await this.updateShow();
    },
    bookForVisitor() {
      this.$router.push(`/staff/planning/${this.$route.params.id}/book`)
    },
    async startScanning() {
      await this.$api.shows.startScanning(this.showId)
      await this.updateShow();
    },
    async endScanning() {
      await this.$api.shows.endScanning(this.showId)
      await this.updateShow();
    },
    async updateShow(){
      this.show = await this.$api.shows.byId(this.showId)
      this.canAdmin = PersistentStore.allowedSalles.includes(this.show.salle);
      this.next = (await this.$api.shows.last_called(this.showId))?.data?.number ?? "";
      console.log( this.show)
    },
    async nextTicket(){
      const result = await this.$api.shows.next(this.showId);
      if(result.status !== 200){
        this.next = "";
      } else {
        this.next = (await this.$api.shows.last_called(this.showId))?.data?.number ?? "";
      }
    }
  }
}
</script>

<style scoped>
h2, h3{
  margin: 0 0 20px 0;
}
.page{
  margin: 20px;
  padding: 20px;
  background-color: white;
  border-radius: 6px;
}
.outline{
  color: #7643D6;
  border-color: #7643D6;
}
.delete{
  background-color: #c3524f;
}
table{
  width: 100%;
  text-align: left;
}

table .first{
  padding: 15px 0px;
}
</style>