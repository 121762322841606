import {createWebHistory, createRouter} from "vue-router";
import InBoarding from "@/components/Visitor/InBoarding";
import Home from "@/components/Visitor/Home";
import About from "@/components/Visitor/InBoarding/About";
import AddQrCode from "@/components/Visitor/InBoarding/AddQrCode";
import {PersistentStore} from "@/services/PersistentStore";
import Planning from "@/components/Common/Planning/Planning";
import Tickets from "@/components/Visitor/Tickets";
import AddTicket from "@/components/Visitor/AddTicket";
import Book from "@/components/Visitor/Book";
import ChooseTicket from "@/components/Visitor/book/ChooseTicket";
import Finalize from "@/components/Visitor/book/Finalize";
import Infos from "@/components/Visitor/Infos";
import Staff from "@/components/Staff/Staff";
import Scan from "@/components/Staff/Scan";
import Login from "@/components/Staff/Login";
import InfosStaff from "@/components/Staff/InfosStaff";
import ActivityDetail from "@/components/Staff/Planning/ActivityDetail";
import StaffBook from "@/components/Staff/Planning/StaffBook";
import Queue from "@/components/Staff/Queue";
//import axios from "axios";
//import Login from "@/components/Staff/Login";

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
        redirect: '/tickets',
        children: [
            {
                path: 'tickets',
                component: Tickets,
            },
            {
                path: 'tickets/add',
                component: AddTicket,
            },
            {
                path: 'dedi',
                component: Planning,
                props: {staff: false, dedi: true}
            },
            {
                path: 'planning',
                component: Planning,
                props: {staff: false, dedi: false}
            },
            {
                path: "infos",
                name: "Infos",
                component: Infos
            },
        ]
    },
    {
        path: "/in-boarding",
        name: "InBoarding",
        component: InBoarding,
        redirect: '/in-boarding/about',
        children: [
            {
                path: 'about',
                component: About,
            },
            {
                path: 'add-qr',
                component: AddQrCode,
            },
        ]
    },
    {
        path: "/book/:id(\\d+)",
        name: "Book",
        component: Book,
        redirect: '/book/tickets',
        children: [
            {
                path: 'tickets',
                component: ChooseTicket,
            },
            {
                path: 'finalize',
                component: Finalize,
            },
        ]
    },
    {
        path: "/staff",
        name: "staff",
        component: Staff,
        redirect: '/staff/scan',
        children: [
            {
                path: 'scan',
                component: Scan,
            },
            {
                path: 'planning',
                component: Planning,
                props: {staff: true}
            },
            {
                path: 'infos',
                component: InfosStaff,
            },
            {
                path: 'queue',
                component: Queue,
            },
            {
                path: 'planning/:id(\\d+)',
                component: ActivityDetail,
            },
            {
                path: 'planning/:id(\\d+)/book',
                component: StaffBook,
            },
        ]
    },
    {
        path: "/staff/login",
        name: "staff_login",
        component: Login
    },
    {path: "/:catchAll(.*)", redirect: '/'}
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {

    PersistentStore.api?.infos?.version()?.then(v => {
        const currentVer = PersistentStore.version;
        if(currentVer !== v){
            PersistentStore.version = v;
            this.$api.clear()
            location.reload(true)
        }

    }).catch(err => console.error(err));

    const isInBoarding = to.matched.length > 0 && to.matched[0].name === "InBoarding"
    const isStaff = to.matched.length > 0 && ["staff", "staff_login"].includes(to.matched[0].name)
    const hasTickets = PersistentStore.tickets.length > 0
    const skipInBoarding = PersistentStore.skipInBoarding;

    if (isStaff) {
        if (!PersistentStore.isStaffLoggedIn && to.name !== 'staff_login') {
            next({name: "staff_login"})
        }
        next()
        return;
    }

    // If the user has no ticket force 'in-boarding '
    if (!hasTickets && !isInBoarding && !skipInBoarding) {
        next({name: "InBoarding"})
        return
    }
    // If the user has one or more ticket any page except 'in-boarding'
    else if ((hasTickets || skipInBoarding) && isInBoarding) {
        next({name: "Home"})
        return
    }


    next()
})

export default router;