<template>
    <div class="alert-container" :class="[color, square ? 'square': '']" >
    <div class="icon">
      <slot name="icon"></slot>
    </div>
    <div class="content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>

export default {
  name: "AlertBase",
  data() {
    return {
      //color: "white"
    }
  },
  components: {},
  props: {
    "color": String,
    "square": Boolean
  }
}
</script>

<style scoped>
.alert-container {
  background-color: orange;
  padding: 10px;
  border-radius: 6px;
  display: flex;
  column-gap: 10px;
  align-items: center;
}

.alert-container.white {
  background-color: white;
  color: #474756;
}

.alert-container.blue {
  background-color: #cfe2ff;
  color: #084298;
}

.alert-container.orange {
  background-color: #ee9e0f;
  color: #ffffff;
}

.alert-container.red {
  background-color: #c3524f;
  color: #ffffff;
}

.alert-container.green {
  background-color: #9dcd94;
  color: #0f5132;
}

.alert-container .icon {
  font-size: 30px;
  display: flex;
  align-items: center;
}

.alert-container.square{
  border-radius: 0;
}
</style>