<template>
  <span>
    <span v-if="show">Réservable dans {{ remaining }}</span>
    <span v-if="!show">{{ finishedText }}</span>
  </span>
</template>

<script>
export default {
  name: "CountDown",
  props: ["finishedText", "targetDate"],
  data() {
    return {
      timer: null,
      remaining: "",
      show: true
    }
  },
  mounted() {
    this.updateTime()

    this.timer = setInterval(() => {
      this.updateTime()
    }, 1000);
  },
  methods: {
    updateTime: function (){
      if(!this.targetDate){
        this.show = false
        return
      }

      this.remaining--
      const now = this.$dayjs();
      const remainingSeconds = Math.round(this.targetDate.diff(now) / 1000)
      this.show = remainingSeconds > 0
      this.remaining = this.formatText(remainingSeconds)
    },
    formatText: function (diffInSeconds) {
      const hours = Math.floor(diffInSeconds / 3600);
      const minutes = Math.floor(diffInSeconds % 3600 / 60);
      const seconds = Math.floor(diffInSeconds % 3600) % 60;

      let formated = this.formatNumber(minutes) + ' min';

      if(hours <= 0 && minutes <= 5){
        return `${formated} ${this.formatNumber(seconds)} sec`
      }

      if(hours > 0){
        return `${hours}h ${formated}`
      }

      return formated
    },
    formatNumber(number){
      return ('00' + number).slice(-2)
    }
  },
  beforeUnmount() {
    clearInterval(this.timer)
  }
}
</script>

<style scoped>

</style>