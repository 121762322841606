import config from "@/config.json";


export default class Booking {

    install(app){
        this.app = app;
        app.$booking = app.config.globalProperties.$booking = this;
    }

    async book(ticketsToBook, showId) {
        if (!ticketsToBook || ticketsToBook.length <= 0) {
            this.app.$toast.error("Tu dois avoir choisi au minimum un ticket");
            return false;
        }

        const barcodes = ticketsToBook.map(t => t.barcode)
        const result = await this.app.$api.bookings.book(showId, barcodes);
        if (!result.data.code && result.status === 200) {
            // Success
            this.app.$toast.success("Réservation enregistrée")
            return true;
        } else if (result.data.code) {
            const message = config.BookingErrorMessages.find(m => m.code === result.data.code).message
            if (typeof message == 'string' || message instanceof String) {
                this.app.$toast.error(message)
            } else {
                this.app.$toast.error(barcodes.length > 1 ? message.multiple : message.one)
            }
        } else {
            // Error
            this.app.$toast.error("Le serveur a répondu avec une erreur inconue")
        }

        return false;
    }
}