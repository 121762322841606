<template>
  <div class="container">
    <ticket-input @scan="onScan"></ticket-input>
  </div>
  <div class="actions">
    <router-link :class="{disabled: !canContinue}"  class="next" to="/" @click="persist" >Continuer <arrow-right></arrow-right></router-link>
  </div>
</template>

<script>
import { ArrowRight  } from 'mdue'
import {PersistentStore} from "@/services/PersistentStore";
import TicketInput from "@/components/Common/TicketInput/TicketInput";

export default {
  name: "AddQrCode",
  data() {
    return {
      ticket: null,
    };
  },
  components: {
    TicketInput,
    ArrowRight
  },
  methods: {
    onScan(ticket){
      this.ticket = ticket
    },
    persist(){
      PersistentStore.addTicket(this.ticket)
    }
  },
  computed: {
    canContinue(){
      return !!this.ticket
    }
  }
}
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 10px 20px;
}

.actions{
  display: flex;
  flex-direction: row-reverse;
  padding: 10px 20px;
}


</style>