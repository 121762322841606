<template>

  <div class="page">
    <select v-model="selectedSalle">
      <option v-for="salle in salles" :value="salle" :key="salle">{{ salle.name }}</option>
    </select>
    <alert-info v-if="!selectedSalle">Choisis ta salle ci-dessus afin de commencer la validation</alert-info>
    <div class="scan" v-if="!!selectedSalle">
      <qr-code-scanner @scan="scan"></qr-code-scanner>
      <div id="divider">ou</div>
      <button-input placeholder="Numéro du billet" action="Vérifier" @input.self="onBarcodeInput" class="shadow"></button-input>
      <p v-if="showName !== ''">Réservation pour: <br><b>{{showName}}</b></p>
    </div>
  </div>
  <div class="bg-filter" :class="{ok: checkStatus === 1, warning: checkStatus===2, error: checkStatus===3}"></div>
</template>

<script>
import QrCodeScanner from "@/components/Common/TicketInput/QrCodeScanner";
import ButtonInput from "@/components/Common/System/Inputs/ButtonInput";
import AlertInfo from "@/components/Common/System/Alerts/Info";
import {PersistentStore} from "../../services/PersistentStore";
import dayjs from "dayjs";

export default {
  name: "Scan",
  data() {
    return {
      salles: [],
      selectedSalle: undefined,
      checkStatus: 0,
      showName: "",
      lastScanQr: ""
    }
  },
  components: {AlertInfo, QrCodeScanner, ButtonInput},
  async mounted() {
    PersistentStore.allowedSalles = await this.$api.salles.allowed();

    this.salles = (await this.$api.salles.all())
      .filter(s => PersistentStore.allowedSalles.includes(s.id));
  },
  methods: {
    async scan(val) {
      try {
        if(this.lastScanQr !== val){
          this.lastScanQr = val
          await this.checkBooking(await this.$api.tickets.getByQrcode(val))
        }
      } catch (e) {
        this.checkStatus = 3
        this.$toast.error("Le billet n'existe pas")
      }
    },
    async onBarcodeInput(val) {
      try {
        await this.checkBooking(await this.$api.tickets.getByBarcode(val))
      } catch (e) {
        this.checkStatus = 3
        this.$toast.error("Le billet n'existe pas")
      }
    },
    async checkBooking(ticket, withAutobook = true) {
      if(this.resetTimeout){
        clearTimeout(this.resetTimeout)
      }

      this.showName = "";
      if (ticket.status === "valid") {
        this.checkStatus = 2
        this.$toast.error("Le billet est valid mais n'a pas été scanné à l'entrée")
        return;
      }

      if (ticket.status !== "scanned") {
        this.checkStatus = 3
        this.$toast.error("Le billet n'est pas valide")
        this.addColorTimeout();
        return;
      }

      if (!ticket.isInValidTimePeriod) {
        this.checkStatus = 3
        this.$toast.error(`Le billet n'est pas valable aujourd'hui. ${ticket.category.name}`)
        this.addColorTimeout();
        return;
      }

      const bookings = (await this.$api.bookings.byBarcode(ticket.barcode, true))
          .filter(b => b.show.salle === this.selectedSalle.id)

      const scannableBookings = bookings.filter(b => b.show.scanning === 1)

      if(/*bookings.length > scannableBookings.length && */scannableBookings.length === 0){
        if(withAutobook && await this.tryBookIfAvailable(this.selectedSalle.id, ticket)){
          await this.checkBooking(ticket, false)
          return;
        }

        this.checkStatus = 3
        this.$toast.error("Aucun booking pour une salle avec scanning ouvert")

        this.addColorTimeout();
        return;
      }

      const unScannedBookings = scannableBookings.filter(b => !b.scanned_in)

      const bookingsInTime = scannableBookings.filter(this.bookingInTime);
      const unScannedBookingsInTime = unScannedBookings.filter(this.bookingInTime);

      if(unScannedBookingsInTime.length === 0 && bookingsInTime.length > 0){
        this.checkStatus = 2
        this.$toast.error("Billet déjà scanné pour cette activité")
        this.showName = bookingsInTime[0].show.name;
        this.addColorTimeout();
        return;
      }

      if(bookingsInTime.length === 0){
        if(withAutobook && await this.tryBookIfAvailable(this.selectedSalle.id, ticket)){
          await this.checkBooking(ticket, false)
          return;
        }

        this.checkStatus = 3
        this.$toast.error("Aucune réservation valide pour ce billet")
        this.addColorTimeout();
        return;
      }

      const bookingToCheckin = unScannedBookingsInTime[0]
      const result = await this.$api.bookings.checkin(bookingToCheckin.id);

      if(result.status === 200) {
        this.showName = bookingToCheckin.show.name;
        this.checkStatus = 1
      }

      if(result.status === 409) {
        this.checkStatus = 2
        this.$toast.error("Billet déjà scanné pour cette activité")
        this.showName = bookingToCheckin?.show?.name ?? '';
      }

      if(result.status === 401) {
        this.$toast.error("Tu n'as pas le droit de valider un billet")
        this.checkStatus = 0
      }

      this.addColorTimeout();
    },
    async tryBookIfAvailable(salleId, ticketId){

      const shows = await this.$api.shows.allStaff();

      const showsForSalle = shows
          .filter(s => s.salle = salleId && s.scanning === 1)
          .sort((a,b) => {
        return dayjs(b.start.date).unix() - dayjs(a.start.date).unix()
      });

      if(showsForSalle.length === 0){
        console.log("no show for salle")
        return false
      }

      const mostRecent = showsForSalle[0]

      const result = await this.$booking.book([ticketId], mostRecent.id);

      if (result) {
        this.checkStatus = 1
        this.$toast.info("Réservation créée automatiquement")
        this.addColorTimeout();
        return true;
      }

      return false
    },
    addColorTimeout(){
      this.resetTimeout = setTimeout(() => {
        this.checkStatus = 0
      }, 3000)
    },
    bookingInTime(booking){
      const now = Date.now() / 1000;
      const endTimeStamp = dayjs(booking.show.end.date).unix()

      return endTimeStamp >= now;
    }
  }
}
</script>

<style scoped>

p{
  color: black;
  font-size: 1.3em;
}

select {
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
}

.page {
  padding: 20px;
  height: 100%;
  z-index: 100;
}

#divider {
  color: black;
}

#divider:after, #divider:before {
  background-color: black;
}


.bg-filter {
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: -1;
  top: 0;
  left: 0;
}

.bg-filter.ok {
  background-color: rgba(12, 182, 12, 0.89);
}

.bg-filter.warning {
  background-color: rgba(238, 143, 34, 0.89);
}

.bg-filter.error {
  background-color: rgba(182, 18, 12, 0.89);

}
</style>